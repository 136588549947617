import axios from "axios"
import qs from 'qs';

const Email = (params: {
    username: string,
    phone: string,
    description?: string,
    model?:string,
    usernum?:string,
    runtime?:string
},asstoken: any,type: any) => {
    return axios(
        {
            method: 'post',
            url: '/api/v1/cloud/purchase-apply',
            headers: {
                'Authorization': `${type} ${asstoken}`
            },
            data: params
        }
        )
}
const getToken = (params = {
    grant_type: 'client_credentials',
    client_id: '650915d7ad9b56ce6c94336e',
    client_secret: '98066354a2b3767900b21d97a91a5228',
    scope: 'purchase-apply:create'
}) => {
    const data = qs.stringify(params)
    return axios({
        method: 'post',
        url: 'https://iam-test.mthreads.com/oidc/token',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
    });
}
export default {
    Email,
    getToken
}
