import './MobilePage.scss'
import giantGate from '../assets/giantGate.svg'
import moblieLogo from '../assets/moblileLogo.svg'
import cancelimg from '../assets/cancel.svg'
import pass_icon from '../assets/pass_icon.png'
import add from '../assets/add.svg'
import API from '../api'
import { MouseEvent, useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, Modal, Select, Space, message, } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons'
export default function MobilePage() {
    const [show, setShow] = useState(false)
    const [formShow, setFormShow] = useState(false)
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({ modal: '', time: '', people: "" })
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [money, setMoney] = useState('0');
    const [iframeKey, setIframeKey] = useState(0);
    const [selectShow, setSelectShow] = useState(false)
    const [iconShow, setIconShow] = useState(true)
    const [chatUrl, setChatUrl] = useState('')
    useEffect(() => {
        if (formData.modal && formData.time && formData.people) {
            let modalmoney: any
            if (formData.modal == 'ChatGLM-6B') {
                modalmoney = 0.0015
            }
            if (formData.modal == 'Llama2-7B') {
                modalmoney = 0.0017
            }
            if (formData.modal == 'Llama2-13B') {
                modalmoney = 0.0033
            }
            let newMoney = (((Number(modalmoney!)) * Number(formData.people) * Number(formData.time) * 3600 * 30) / 1000 * 450).toFixed(0)
            setMoney(newMoney)
        } else {
            setMoney('0')
        }
    }, [formData])
    const showDrawer = (e: string) => {
        setTitle(e)
        setIframeKey(key => key + 1); // 更新iframeKey，重新渲染并加载最初的iframe页面
        setOpen(true);
        if (e === 'ChatGLM-6B') {
            setChatUrl('https://chat3.merakcloud.com/ChatGLM/')
        } else if (e === 'Llama2-7B') {
            setChatUrl('https://chat3.merakcloud.com/Llama2/')
        } else {
            setChatUrl('https://chat3.merakcloud.com/musaChat-13B/')
        }
    };
    const onClose = () => {
        setOpen(false);
    };
    const apply = () => {
        setShow(true)
    }
    const cancel = () => {
        form.resetFields()
        setShow(false)
        setFormShow(false)
    }
    const handleImgClick = () => {
        setIframeKey(key => key + 1); // 更新iframeKey，重新渲染并加载最初的iframe页面
    };
    const onFinish = (e: { username: string, phone: string, description: string }) => {
        API.getToken().then(res => {
            if (res.status === 200) {
                let params
                if (formData.modal && formData.time && formData.people) {
                    params = {
                        username: e?.username,
                        phone: e?.phone,
                        description: e?.description,
                        model: formData?.modal,
                        usernum: formData?.people,
                        runtime: formData?.time.toString()
                    }
                } else {
                    params = {
                        username: e?.username,
                        phone: e?.phone,
                        description: e?.description,
                    }
                }
                API.Email(params, res?.data?.access_token, res?.data?.token_type).then((res: any) => {
                    setFormShow(true)
                }).catch(err => {
                    message.error(err?.response?.data?.message, 3)
                })
            }
        })

    }
    function formatNumberWithCommas(number: any) {
        let roundedNumber = Math.round(number);

        // 将整数转化为字符串形式
        let str = roundedNumber.toString();

        // 使用正则表达式匹配并替换数字部分
        str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return str;
    }
    const inputChange = (e: any) => {
        form.resetFields()
        setFormData({ ...formData, 'people': e.target.value })
    }
    useEffect(() => {
        setFormData({ modal: 'ChatGLM-6B', time: '2', people: "10" })
    }, [])
    const timeChange = (e: string) => {
        form.resetFields()
        setFormData({ ...formData, 'time': e })
    }


    const setValue = (value: string) => {
        setFormData({ ...formData, 'modal': value })
        setSelectShow(false)
        setIconShow(true)
    }
    const events = (e: any) => {
        e.preventDefault()
        e.stopPropagation();
        setIconShow(false)
        setSelectShow(!selectShow)
    }
    const selectDefult = (e: any) => {
        e.preventDefault()
        e.stopPropagation();
    }
    return (
        <div className="boxMobile">
            <div className='header'>
                <div className='moblieLogo'><img src={moblieLogo} alt="" /></div>
            </div>
            <div className='main'>
                <div className='toChart'>
                    <span>ChatGLM-6B</span>
                    <div onClick={() => showDrawer('ChatGLM-6B')}>立即体验</div>
                </div>
                <div className='toChart'>
                    <span>Llama2-7B</span>
                    <div onClick={() => showDrawer('Llama2-7B')}>立即体验</div>
                </div>
                <div className='toChart'>
                    <span>Llama2-13B</span>
                    <div onClick={() => showDrawer('Llama2-13B')}>立即体验</div>
                </div>
            </div>
            <div className='count'>
                <div className='countBox'>
                    <div className='calculator'>购买费用计算器</div>
                    <div className='modal'>
                        <div>
                            <div className='formitem'>
                                <div className='divs'>模型</div>
                                <Select
                                    bordered={false}
                                    onClick={(e) => events(e)}
                                    value={formData.modal}
                                    className='selectIcon'
                                    dropdownStyle={{ height: 0, display: 'none' }}
                                />
                                {iconShow ? <DownOutlined className='icons' /> : <UpOutlined className='icons' />}
                            </div>
                            {selectShow && <div className='formitems'>
                                <div style={formData.modal === 'ChatGLM-6B' ? { color: '#2E67D1' } : {}} onClick={() => setValue('ChatGLM-6B')} className='contents'>ChatGLM-6B</div>
                                <div style={formData.modal === 'Llama2-7B' ? { color: '#2E67D1' } : {}} onClick={() => setValue('Llama2-7B')} className='contents'>Llama2-7B</div>
                                <div style={formData.modal === 'Llama2-13B' ? { color: '#2E67D1' } : {}} onClick={() => setValue('Llama2-13B')} className='contents'>Llama2-13B</div>
                            </div>}
                            <div className='formitem'>
                                <div className='divs'>同时在线用户</div>
                                <Input type='number' onChange={inputChange} className='antinput' value={formData.people} />
                                <span className='times'>个</span>
                            </div>
                            <div className='formitem'>
                                <div className='divs'>满载状态下运行</div>
                                <Select
                                    className='selectDown'
                                    style={{ width: 120 }}
                                    bordered={false}
                                    defaultValue={'2'}
                                    onChange={timeChange}
                                    onClick={(e) => selectDefult(e)}
                                    value={formData.time}
                                    options={[
                                        { value: 1, label: 1 },
                                        { value: 2, label: 2 },
                                        { value: 3, label: 3 },
                                        { value: 4, label: 4 },
                                        { value: 5, label: 5 },
                                        { value: 6, label: 6 },
                                        { value: 7, label: 7 },
                                        { value: 8, label: 8 },
                                        { value: 9, label: 9 },
                                        { value: 10, label: 10 },
                                        { value: 11, label: 11 },
                                        { value: 12, label: 12 },
                                        { value: 13, label: 13 },
                                        { value: 14, label: 14 },
                                        { value: 15, label: 15 },
                                        { value: 16, label: 16 },
                                        { value: 17, label: 17 },
                                        { value: 18, label: 18 },
                                        { value: 19, label: 19 },
                                        { value: 20, label: 20 },
                                        { value: 21, label: 21 },
                                        { value: 22, label: 22 },
                                        { value: 23, label: 23 },
                                        { value: 24, label: 24 },
                                    ]} />
                                <span className='times'>小时</span>
                            </div>
                        </div>
                    </div>
                    <div className='money'>
                        <span className='spend'>预计每月花费</span>
                        <span className='amount'>￥{formatNumberWithCommas(money)}</span>
                    </div>
                    <div className='save'>最高节省￥{formatNumberWithCommas((Number(money) * 4))}</div>
                    <div onClick={() => apply()} className='btnDiv'>
                        <div className='btns'>
                            立即体验
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>商务邮箱  service@merakcloud.com</div>
            <div className='grounds'></div>
            <Modal
                centered={true}
                footer={null}
                open={show}
                // width={365}
                className='modalStyle'
            >
                <div className='modalTitle'>
                    <span>申请资料填写</span>
                    <div></div>
                </div>
                {!formShow && <Form
                    layout="vertical"
                    form={form}
                    name="dynamic_rule"
                    style={{ maxWidth: 600 }}
                    onFinish={onFinish}
                    initialValues={formData}
                >
                    {formData.modal && formData.time && formData.people ?
                        <>
                            <div className='disabledLabel'>模型</div>
                            <Input style={{ marginBottom: 15, height: 33 }} bordered value={formData.modal} className='ant-input' disabled />
                            <div className='disabledLabel'>满载状态下运行</div>
                            <Input style={{ marginBottom: 15, height: 33 }} bordered value={formData.time} className='ant-input' disabled />
                            <div className='disabledLabel'>预计支撑</div>
                            <Input style={{ marginBottom: 15, height: 33 }} bordered value={formData.people} className='ant-input' disabled />
                        </>
                        : ''}
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '请输入个人或企业名称' }]}
                    >
                        <Input maxLength={128} className='ant-input' placeholder="*个人/企业名称" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true, validator(rule, value) {
                                    if (!value) {
                                        return Promise.reject(new Error('请输入联系方式'))
                                    }
                                    if (!/^1[3456789]\d{9}$/.test(value)) {
                                        return Promise.reject(new Error('请输入正确的手机号'))
                                    }
                                    return Promise.resolve()
                                }
                            },
                        ]}
                    >
                        <Input className='ant-input' placeholder="*联系方式（手机号）" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label='购买服务的需求描述'
                    >
                        <Input.TextArea maxLength={200} className='ant-input' placeholder="200字以内" />
                    </Form.Item>
                    <div className='formFooter'>以上内容将发送邮件到：service@merakcloud.com</div>
                    <Form.Item ><div className='formButton'>
                        <Button onClick={() => cancel()} className='cancel'>取消</Button>
                        <Button htmlType="submit" className='submit'>提交申请</Button>
                    </div>
                    </Form.Item>
                </Form>}
                {formShow && <div className='SuccessfulPop-up'>
                    <div className='characters' style={{ marginTop: 20 }}>申请已提交</div>
                    <div className='img'><img src={pass_icon} alt="" /></div>
                    <div className='characters'>我们会尽快对您的申请进行邮件回复</div>
                    <Button onClick={cancel} className='cancelbtn'>返回</Button>
                </div>}
            </Modal>
            <Drawer
                className='drawer'
                width={'100%'}
                title={title}
                placement="right"
                onClose={onClose}
                closeIcon={false}
                open={open}
            >
                <img onClick={onClose} className='cancelimg' src={cancelimg} alt="" />
                <div onClick={handleImgClick} className='addL'>
                    <img style={{ marginRight: 6 }} src={add} alt="" />
                    <span>新聊天</span>
                </div>
                <iframe key={iframeKey} style={{ width: '100%', height: '83vh', padding: 0, border: 'none' }}
                    src={chatUrl}>
                </iframe>
            </Drawer>
        </div>
    )
}
