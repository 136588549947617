import PcPage from "./components/PcPage"
import MobilePage from "./components/MobilePage"
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
export default function App() {
  const [isMobile, setIsMobile] = useState<any>();
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 500);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   // 初始化时获取当前窗口宽度
  //   handleResize();
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [window.innerWidth])
    const isDesktopOrLaptop = useMediaQuery({
      query: "(min-width:600px)",
    });
  return (
    <div>
      {isDesktopOrLaptop ? (
        <PcPage />
      ) : (
        <MobilePage />
      )}
    </div>
  )
}