import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.svg'
import cancelimg from '../assets/cancel.svg'
import pass_icon from '../assets/pass_icon.png'
import API from '../api'
import add from '../assets/add.svg'
import eighty from '../assets/eightys.svg'
import tokens from '../assets/tokens.svg'
import './PcPage.less'
import { Button, Drawer, Form, Input, Modal, Select, Space, message } from 'antd';

export default function PcPage() {
  const [show, setShow] = useState(false)
  const [formShow, setFormShow] = useState(false)
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({ modal: '', time: '', people: "" })
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [money, setMoney] = useState('0');
  const [iframeKey, setIframeKey] = useState(0);
  const [chatUrl, setChatUrl] = useState('')
  const apply = () => {
    setShow(true)
  }
  useEffect(() => {
    if (formData.modal && formData.time && formData.people) {
      let modalmoney: any
      if (formData.modal == 'ChatGLM-6B') {
        modalmoney = 0.0015
      }
      if (formData.modal == 'Llama2-7B') {
        modalmoney = 0.0017
      }
      if (formData.modal == 'Llama2-13B') {
        modalmoney = 0.0033

      }
      //                 0.0015                       10                   3600          2      *     30  / 10000
      // ：每月花费 = 模型单价（￥ k tokens）*  QPS（约用户同时在线平均数）* 3600 * 日运行小时数 * 30（月）/ 1000
      let newMoney = (((Number(modalmoney!)) * Number(formData.people) * 3600 * Number(formData.time) * 30) / 1000 * 450).toFixed(0)
      setMoney(newMoney)
    } else {
      setMoney('0')
    }
  }, [formData])
  useEffect(() => {
    setFormData({ modal: 'ChatGLM-6B', time: '2', people: "10" })
  }, [])
  const cancel = () => {
    form.resetFields()
    setShow(false)
    setFormShow(false)
  }
  const handleImgClick = () => {
    setIframeKey(key => key + 1); // 更新iframeKey，重新渲染并加载最初的iframe页面
  };
  const onFinish = (e: { username: string, phone: string, description: string }) => {
    API.getToken().then(res => {
      if (res.status === 200) {
        let params
        if (formData.modal && formData.time && formData.people) {
          params = {
            username: e?.username,
            phone: e?.phone,
            description: e?.description,
            model: formData?.modal,
            usernum: formData?.people,
            runtime: formData?.time.toString()
          }
        } else {
          params = {
            username: e?.username,
            phone: e?.phone,
            description: e?.description,
          }
        }
        API.Email(params, res?.data?.access_token, res?.data?.token_type).then((res: any) => {
          setFormShow(true)
        }).catch(err => {
          message.error(err?.response?.data?.message, 3)
        })
      }
    })

  }
  const modalChange = (e: string) => {
    form.resetFields()
    setFormData({ ...formData, 'modal': e })
  }
  const timeChange = (e: string) => {
    form.resetFields()
    setFormData({ ...formData, 'time': e })
  }
  const inputChange = (e: any) => {
    form.resetFields()
    setFormData({ ...formData, 'people': e.target.value })
  }
  const showDrawer = (e: string) => {
    setTitle(e)
    setIframeKey(key => key + 1); // 更新iframeKey，重新渲染并加载最初的iframe页面
    setOpen(true);
    if (e === 'ChatGLM-6B') {
      setChatUrl('https://chat3.merakcloud.com/ChatGLM/')
    } else if (e === 'Llama2-7B') {
      setChatUrl('https://chat3.merakcloud.com/Llama2/')
    } else {
      setChatUrl('https://chat3.merakcloud.com/musaChat-13B/')
    }
  };
  const onClose = () => {
    setOpen(false);
  };
  function formatNumberWithCommas(number: any) {
    let roundedNumber = Math.round(number);

    // 将整数转化为字符串形式
    let str = roundedNumber.toString();

    // 使用正则表达式匹配并替换数字部分
    str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return str;
  }
  return (
    <div className='box'>
      <div className='hearder'>
        <img className='logo' src={logo} alt="" />

        <div style={{width:"100%",display:"flex",alignItems:"start",justifyContent:"center"}}>
          <div style={{width:1600,display:"flex",flexDirection: "row",}}>
            <div className='compare'>
              <div className='chart'>
                <span className='numgolden'>0.0012</span>
                <span className='num'>0.006</span>
                <div className='column'></div>
                <div className='colorcolumn'></div>
                <div className='line'></div>
                <div style={{ display: 'flex' }}>
                  <div className='jmName'>巨门</div>
                  <div className='business'>行业均价</div></div>
              </div>
            </div>
            <div style={{ marginLeft: 40}}>
              <div className='bigModal'>推理服务价格</div>
              <div className='price'>
                <div className='pricespan'>节省</div>
                <img className='eighty'  style={{marginLeft:21}} src={eighty} alt="" />
              </div>
              <div className=' lowas'>低至</div>
              <div style={{display:'flex'}}>
                <img className='tokens' style={{marginTop:12}} src={tokens} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mains' style={{display:"flex",width:"100%",alignItems:"center",flexDirection:"column"}}>
        <div className='flexModal'>
          <div className='experience'>
            <div className='modalName'>ChatGLM-6B</div>
            <div onClick={() => showDrawer('ChatGLM-6B')} className='btn'>
              立即体验
            </div>
          </div>
          <div className='experience'>
            <div className='modalName' >Llama2-7B</div>
            <div onClick={() => showDrawer('Llama2-7B')} className='btn'>
              立即体验
            </div>
          </div>
          <div className='experience'>
            <div className='modalName' >Llama2-13B</div>
            <div onClick={() => showDrawer('Llama2-13B')} className='btn'>
              立即体验
            </div>
          </div>
        </div>
        <div className='middle'>
          <div className='title'>购买费用估算器</div>
          <div className='select' style={{ marginLeft: '-12px' }}>
            <div className='SelectModel'>
              <div style={{ marginRight: 12 }}>我需要</div>
              <Select
                className='selectOne'
                style={{ width: 368 }}
                bordered={false}
                defaultValue={'ChatGLM-6B'}
                onChange={modalChange}
                options={[
                  { value: 'ChatGLM-6B', label: 'ChatGLM-6B' },
                  { value: 'Llama2-7B', label: 'Llama2-7B' },
                  { value: 'Llama2-13B', label: 'Llama2-13B' },
                ]}
              />
              <div style={{ marginLeft: 12 }}>模型</div>
            </div>
          </div>
          <div className='select' style={{ marginLeft: 25 }}>
            <div style={{ marginTop: 13, paddingRight: 20 }}>预计支撑</div>
            <div className="mock-block">
              <span>
                <Input onChange={inputChange} value={formData.people} type='number' className='input' width={120} bordered={false} />
              </span>
              个用户同时在线访问,</div>
            <div style={{ marginRight: 16, marginTop: 12 }}>每日满载状态下运行</div>
            <div className='SelectModel'>
              <Select
                style={{ width: 120 }}
                bordered={false}
                defaultValue={'2'}
                // dropdownStyle={{ background: '#000000', }}
                onChange={timeChange}
                options={[
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                  { value: 5, label: 5 },
                  { value: 6, label: 6 },
                  { value: 7, label: 7 },
                  { value: 8, label: 8 },
                  { value: 9, label: 9 },
                  { value: 10, label: 10 },
                  { value: 11, label: 11 },
                  { value: 12, label: 12 },
                  { value: 13, label: 13 },
                  { value: 14, label: 14 },
                  { value: 15, label: 15 },
                  { value: 16, label: 16 },
                  { value: 17, label: 17 },
                  { value: 18, label: 18 },
                  { value: 19, label: 19 },
                  { value: 20, label: 20 },
                  { value: 21, label: 21 },
                  { value: 22, label: 22 },
                  { value: 23, label: 23 },
                  { value: 24, label: 24 },
                ]}
              /> <span style={{ marginTop: 6, marginLeft: 15 }}>个小时</span></div>
          </div>
          <div className='estimated'>
            <span className='cost'>预计每月花费</span>
            <span className='money'>￥{formatNumberWithCommas(money)}</span>
            <span className='save'>最高节省 <span>￥{formatNumberWithCommas((Number(money) * 4))}</span></span>
          </div>
          <div className='btns' onClick={() => apply()} >购买申请
          </div>
        </div>
      </div>

      <div style={{display:"flex",width:"100%",alignItems:"center",flexDirection:"column"}}>
        <div className='footera'>商务邮箱 service@merakcloud.com</div>
      </div>
      <div style={{ height: 1 }}></div>
      <Modal
        centered={true}
        footer={null}
        open={show}
        className='modalStyle'
      >
        <div className='modalTitle'>
          <span>申请资料填写</span>
          <div></div>
        </div>
        {!formShow && <Form
          layout="vertical"
          form={form}
          name="dynamic_rule"
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          initialValues={formData}
        >
          {formData.modal && formData.time && formData.people ?
            <>
              <div className='disabledLabel'>模型</div>
              <Input style={{ marginBottom: 15, height: 33 }} bordered value={formData.modal} className='ant-input' disabled />
              <div className='disabledLabel'>满载状态下运行</div>
              <Input style={{ marginBottom: 15, height: 33 }} bordered value={formData.time} className='ant-input' disabled />
              <div className='disabledLabel'>预计支撑</div>
              <Input style={{ marginBottom: 15, height: 33 }} bordered value={formData.people} className='ant-input' disabled />
            </>
            : ''}
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入个人或企业名称' }]}
          >
            <Input maxLength={128} className='ModalInput' placeholder="*个人/企业名称" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true, validator(rule, value) {
                  if (!value) {
                    return Promise.reject(new Error('请输入联系方式'))
                  }
                  if (!/^1[3456789]\d{9}$/.test(value)) {
                    return Promise.reject(new Error('请输入正确的手机号'))
                  }
                  return Promise.resolve()
                }
              },
            ]}
          >
            <Input className='ModalInput' placeholder="*联系方式（手机号）" />
          </Form.Item>
          <Form.Item
            name="description"
            label='购买服务的需求描述'
          >
            <Input.TextArea maxLength={200} className='ModalInput' placeholder="200字以内" />
          </Form.Item>
          <div className='formFooter'>以上内容将发送邮件到：service@merakcloud.com</div>
          <Form.Item ><div className='formButton'>
            <Button onClick={() => cancel()} className='cancel'>取消</Button>
            <Button htmlType="submit" className='submit'>提交申请</Button>
          </div>
          </Form.Item>


        </Form>}
        {formShow && <div className='SuccessfulPop-up'>
          <div className='characters' style={{ marginTop: 20 }}>申请已提交</div>
          <div className='img'><img src={pass_icon} alt="" /></div>
          <div className='characters'>我们会尽快对您的申请进行邮件回复</div>
          <Button onClick={cancel} className='cancelbtn'>返回</Button>
        </div>}
      </Modal>
      <Drawer
        className='drawer'
        width={400}
        title={title}
        placement="right"
        onClose={onClose}
        closeIcon={false}
        open={open}
      >
        <img onClick={onClose} className='cancelimg' src={cancelimg} alt="" />
        <div onClick={handleImgClick} className='addL'>
          <img style={{ marginRight: 6 }} src={add} alt="" />
          <span>新聊天</span>
        </div>
        <iframe key={iframeKey} style={{ width: '100%', height: '83vh', padding: 0, border: 'none' }}
          src={chatUrl}>
        </iframe>
      </Drawer>
    </div>
  );
}
